import React from "react";

const ProjectGalleryBrasil = () => {
  return (
    <section className="projdtal">
      <div className="popup-img">
        <div className="row">
          <a href="#" className="col-md-3 popimg">
            <img alt="" src="/img/portfolio/probono/1.jpg" />
          </a>
          <a href="#" className="col-md-3 popimg">
            <img alt="" src="/img/portfolio/probono/2.jpg" />
          </a>
          <a href="#" className="col-md-3 popimg">
            <img alt="" src="/img/portfolio/probono/3.jpg" />
          </a>
          <a href="#" className="col-md-3 popimg">
            <img alt="" src="/img/portfolio/probono/5.jpg" />
          </a>
          <a href="#" className="col-md-12 popimg">
            <img alt="" src="/img/portfolio/probono/7.jpg" style={{ marginTop: '30px' }} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default ProjectGalleryBrasil;
